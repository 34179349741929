export function getFromLocalStorage(key: string): string | null {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(key);
  }
  return null;
}

export function getFromSessionStorage(key: string): string | null {
  if (typeof sessionStorage !== 'undefined') {
    return sessionStorage.getItem(key);
  }
  return null;
}

export const smoothScrollToDiv = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};


const validateBTCAddress = (address: string): boolean => {
  return /^(1|3|bc1)[a-zA-Z0-9]{25,59}$/.test(address);
};

const validateETHAddress = (address: string): boolean => {
  return /^0x[a-fA-F0-9]{40}$/.test(address);
};

const validateTRONAddress = (address: string): boolean => {
  return /^T[a-zA-Z0-9]{33}$/.test(address);
};

const validateLTCAddress = (address: string): boolean => {
  return /^(L|M|ltc1)[a-zA-Z0-9]{25,59}$/.test(address);
};

export const validateAddress = ({
  address,
  network,
}: {address: string, network: string}): boolean => {
  switch (network) {
    case 'BTC':
      return validateBTCAddress(address);
    case 'ETH':
      return validateETHAddress(address);
    case 'TRON':
      return validateTRONAddress(address);
    case 'LTC':
      return validateLTCAddress(address);
    default:
      return false;
  }
};